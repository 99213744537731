<template>
  <div class="flex h-full bg-cream">
    <div class="m-auto w-full">
      <div class="flex items-start justify-center space-x-10">
        <BackButton class="mt-3" @onClick="goBack"/>
        <div class="flex flex-col text-lg mb-2 w-100">
          <p class="heading-l-500 mb-5 capitalize">Pause job</p>
          <p v-if="proratedAmount" class="body-l-400 mb-4">
            A prorated amount of <b>{{ proratedAmount | currency }}</b> will be charged today. You can restart this job anytime from the job menu.
          </p>
          <p class="body-l-400">Are you sure you want to continue?</p>

          <ButtonIcon
            :text="confirmButtonText"
            font-weight="font-normal"
            x-padding="px-4"
            :loading="loading"
            :disabled="loading"
            class="w-full mt-16"
            @onClick="pauseJob"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapMutations, mapState } from 'vuex';
import BackButton from "@/components/shared/buttons/BackButton";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";

export default {
  name: 'PauseJobModal',
  components: { BackButton, ButtonIcon },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      job: state => state.jobs.current,
    }),

    proratedAmount() {
      return this.job.prorated_amount;
    },

    confirmButtonText(){
      let text = 'Pause the job';
      if (this.proratedAmount && this.proratedAmount > 0) {
        text += ` and pay $${this.proratedAmount} today`;
      }
      return text;
    },
  },

  methods: {
    ...mapMutations({
      setJob: "jobs/setJob",
      updateItem: "home/updateItem",
      showModal: 'general/showModal',
    }),

    async pauseJob(){
      this.loading = true;
      try {
        const { data } = await api.post(`jobs/${this.job.id}/pause`);
        if (data.success) {
            this.$toast.success('Job is paused now');
            this.setJob(data.data);
            this.goBack();
            this.updateItem({ id: this.job.id, data: { status: 'inactive' } });
        }
      } catch (error) {
        console.log(error);
        this.$toast.error('There was an error. Try again later.');
      } finally {
        this.loading = false;
      }
    },

    goBack() {
      this.showModal({ componentName: 'JobModal', props: { jobId: this.job.id }});
    },
  },
}
</script>